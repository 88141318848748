<template>
    <div class="desktop-trip-preview-card" v-if="translates && translates[langUrl] && trip">
        <div class="desktop-trip-preview-card__carousel">
            <div :style="`background-image: url(${ imageSrc(trip.pictures[trip.mainPictureIndex]) })` " class="desktop-trip-preview-card__img" @click="open( trip.code )"></div>
            <div class="desktop-trip-preview-card__carousel__labels">
                <div v-if="showType" class="desktop-trip-preview-card__carousel__labels__item yellow">{{ TRIP_TYPE_TITLE[trip.type] }}</div>
                <div v-if="trip.label" class="desktop-trip-preview-card__carousel__labels__item">{{ formattedLabels }}</div>
                <div v-if="trip.soldOut" class="desktop-trip-preview-card__carousel__labels__item soldOut">{{ translates[langUrl].label_soldOut[lang] }}</div>
            </div>
        </div>
        <div class="desktop-trip-preview-card__info-container">
            <div class="desktop-trip-preview-card__info">
                <div class="desktop-trip-preview-card__title-container">
                    <h3 class="desktop-trip-preview-card__title">{{ trip.name }}</h3>
                </diV>
                <div v-if="!bloggersList" class="desktop-trip-preview-card__details-container">
                    <template v-if="nearestArrival && trip.type !== 'host'">
                        <div class="desktop-trip-preview-card__details-container__details">
                            <span class="desktop-trip-preview-card__details-container__price">
                                <span v-html="priceFormated(nearestArrival.price, nearestArrival.currency)"></span>
                            </span> / {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}
                        </div>
                        <div class="desktop-trip-preview-card__details-container__dates">
                            <img class="desktop-trip-preview-card__details-container__dates__icon" src="../../../assets/Calendar2.svg" />
                            <div class="desktop-trip-preview-card__details-container__dates__text">
                                {{ nearestArrival.dates }}
                            </div>
                        </div>
                    </template>
                    <template v-else-if="trip.type === 'host'">
                        <div class="desktop-trip-preview-card__details-container__details">
                            <span class="desktop-trip-preview-card__details-container__price">
                                <span v-html="priceFormated(trip.price, trip.currency)"></span>
                            </span> / {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}
                        </div>
                    </template>
                    <div v-else class="desktop-trip-preview-card__details-container__dates">
                        <div class="desktop-trip-preview-card__details-container__dates__text" v-html="translates[langUrl].arrivals_noActive[lang]"></div>
                    </div>
                </div>
            </div>
            <div class="desktop-trip-preview-card__description-and-buttons">
                <div class="desktop-trip-preview-card__description">
                    <div class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">{{ translates[langUrl].arrival_start[lang] }}</div>
                        <div v-if="trip.direction[0]" class="desktop-trip-preview-card__description__value"> 
                            <span>{{ trip.direction[0].country? trip.direction[0].country.title: '' }}</span>
                            <span>{{ trip.direction[0].state? ', ' + trip.direction[0].state.title: '' }}</span>
                            <span>{{ trip.direction[0].city? ', ' + trip.direction[0].city.title: '' }}</span>
                        </div>
                    </div>
                    <div class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">{{ translates[langUrl].arrival_finish[lang] }}</div>
                        <!-- <div class="desktop-trip-preview-card__description__value"> {{ finish() }} </div> -->
                        <div v-if="trip.direction[0]" class="desktop-trip-preview-card__description__value"> 
                            <span>{{ trip.direction[trip.direction.length - 1].country? trip.direction[trip.direction.length - 1].country.title: '' }}</span>
                            <span>{{ trip.direction[trip.direction.length - 1].state? ', ' + trip.direction[trip.direction.length - 1].state.title : '' }}</span>
                            <span>{{ trip.direction[trip.direction.length - 1].city? ', ' + trip.direction[trip.direction.length - 1].city.title: '' }}</span>
                        </div>
                    </div>
                    <div v-if="trip.mainType" class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">{{ translates[langUrl].trip_type[lang] }}</div>
                        <div class="desktop-trip-preview-card__description__value">
                            {{ formattedMainType }}<template v-if="trip.additionalTypes.length !== 0 && trip.additionalTypes[0] !==''">, {{ formattedAdditionalTypes[0] }}</template>
                            <span v-if="trip.additionalTypes.length > 1" class="desktop-trip-preview-card__description__span"> ещё +{{ trip.additionalTypes.length - 1 }} </span>
                        </div>
                    </div>
                    <div v-if="(trip.organizer || trip.user) && !bloggersList" class="desktop-trip-preview-card__description__line">
                        <div class="desktop-trip-preview-card__description__name">{{ translates[langUrl].organizer[lang] }}</div>
                        <div v-if="trip.organizer && trip.organizer.firstname" class="desktop-trip-preview-card__description__value">{{ trip.organizer.firstname }} {{ trip.organizer.lastname }}</div>
                        <div v-else class="desktop-trip-preview-card__description__value">{{ trip.user.firstname }} {{ trip.user.lastname }}</div>
                    </div>
                    <!-- <div class="desktop-trip-preview-card__description__line party-line">
                        <div class="desktop-trip-preview-card__description__name ">Участники (28):</div>
                        <div class="desktop-trip-preview-card__description__party">
                            <img src="../../../assets/Ellipse1.png" class="desktop-trip-preview-card__description__party__participant" />
                            <img src="../../../assets/Ellipse2.png" class="desktop-trip-preview-card__description__party__participant" />
                            <img src="../../../assets/Ellipse3.png" class="desktop-trip-preview-card__description__party__participant" />
                            <img src="../../../assets/Ellipse4.png" class="desktop-trip-preview-card__description__party__participant" />
                            <img src="../../../assets/Ellipse5.png" class="desktop-trip-preview-card__description__party__participant" />
                        </div>
                    </div> -->
                    <template v-if="!marketplace">
                        <div v-if="buttons.indexOf('moderated') >= 0" class="desktop-trip-preview-card__description__line">
                            <div class="desktop-trip-preview-card__description__name">{{ translates[langUrl].trip_link[lang] }}</div>
                            <a class="desktop-trip-preview-card__description__value" :href="testLink">{{ testLink }}</a>
                        </div>
                        <div v-if="buttons.indexOf('moderated') >= 0" class="desktop-trip-preview-card__description__line">
                            <div class="desktop-trip-preview-card__description__name">{{ translates[langUrl].trip_siteLink[lang] }}</div>
                            <a class="desktop-trip-preview-card__description__value" :href="subdomain">{{ subdomain }}</a>
                        </div>
                    </template>
                    <div v-if="trip.PDF" class="desktop-trip-preview-card__description__pdf" @click="downloadProgram">{{ translates[langUrl].trip_presentation[lang] }}
                        <img src="../../../assets/DownloadDoc.svg" class="desktop-trip-preview-card__button__icon" />
                    </div>
                </div>
                <!-- <div class="button desktop-trip-preview-card__button-grey" @click="duration(trip.programs)">Смотреть участников</div> -->
                <div class="desktop-trip-preview-card__buttons">
                    <div
                        v-if="buttons.indexOf('edit') >= 0"
                        class="button desktop-trip-preview-card__button"
                        @click="edit( trip._id )">{{ translates[langUrl].button_edit[lang] }}</div>
                    <div
                        v-if="buttons.indexOf('copy') >= 0"
                        class="button desktop-trip-preview-card__button"
                        @click="copy( trip._id )">{{ translates[langUrl].button_copy[lang] }}</div>
                    <div
                        v-if="buttons.indexOf('favorites') >= 0 && !inFavorites"
                        class="button desktop-trip-preview-card__button desktop-trip-preview-card__button-fill"
                        @click="favoriteAdd( trip._id )">{{ translates[langUrl].button_favoriteAdd[lang] }}</div>
                    <div
                        v-if="buttons.indexOf('favorites') >= 0 && inFavorites"
                        class="button desktop-trip-preview-card__button"
                        @click="favoriteDelete( trip._id )">{{ translates[langUrl].button_favoriteDelete[lang] }}</div>
                    <div 
                        class="button desktop-trip-preview-card__button desktop-trip-preview-card__button-fill" 
                        @click="open( trip.code )">{{ translates[langUrl].button_detail[lang] }}</div>
                        
                    <div 
                        v-if="buttons.indexOf('moderated') >= 0 && trip.status === TRIP_STATUS.NEED_USER"
                        class="button desktop-trip-preview-card__button desktop-trip-preview-card__button-fill" 
                        @click="$router.push({ name: 'user-profile-edit' })">{{ translates[langUrl].button_profileEdit[lang] }}</div>
                    <div 
                        v-if="buttons.indexOf('moderated') >= 0 && trip.status === TRIP_STATUS.DRAFT"
                        class="button desktop-trip-preview-card__button desktop-trip-preview-card__button-fill" 
                        @click="setStatus( trip._id, TRIP_STATUS.MODERATED )">{{ translates[langUrl].button_createSite[lang] }}</div>
                    <div 
                        v-if="buttons.indexOf('moderated') >= 0 && (trip.status === TRIP_STATUS.MODERATED || trip.status === TRIP_STATUS.ACTIVE)"
                        class="button desktop-trip-preview-card__button" 
                        @click="setStatus( trip._id, TRIP_STATUS.DRAFT )">{{ translates[langUrl].button_draft[lang] }}</div>
                </div>
            <!-- <div class="desktop-trip-preview-card__buttons">
            <div class="button desktop-trip-preview-card__button">Скачать PDF
                <img src="../../../assets/DownloadDoc.svg" class="desktop-trip-preview-card__button__icon" />
            </div>
            <div class="button desktop-trip-preview-card__button desktop-trip-preview-card__button-fill" @click="open( trip.code )">Подробнее</div>
        </div> -->
            </div>
        </div>
        <v-dialog
            v-model="popupDownload"
            max-width="600px"
        >
            <PopupDownload
                :code="trip.code"
                @close="popupDownload = false"
            />
        </v-dialog>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from '@/plugins/moment'
    import { mapState } from 'vuex';
    import { TRIP_STATUS, TRIP_STATUS_TITLE, TRIP_TYPE_TITLE, FEEDBACK_TYPE } from '@/vars';
    import PopupDownload from '../../common/PopupDownload.vue';

    import { imageSrc, priceFormated, lastDigitToWord } from '@/helpers';

    export default {
        name: 'TripPreviewCard',
        components: {
            PopupDownload
        },
        props: {
            trip: {
                type: Object,
                default: () => {},
            },
            showType: {
                type: Boolean,
                default: false,
            },
            buttons: {
                type: Array,
                default: () => []
            },
            bloggersList: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            TRIP_TYPE_TITLE,
            FEEDBACK_TYPE,
            imageSrc,
            lastDigitToWord,
            priceFormated,
            TRIP_STATUS,
            TRIP_STATUS_TITLE,
            popupDownload: false,
            langUrl: '/trips_previewCard'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('dictionaries', ['types', 'labels']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            marketplace() {
                return process.env.VUE_APP_SITE_TYPE === 'marketplace';
            },
            nearestArrival() {
                if (this.trip.bloggerTrips && this.trip.bloggerTrips[0] && this.trip.bloggerTrips[0].start && this.trip.bloggerTrips[0].finish) {
                    return {
                        price: +this.trip.bloggerTrips[0].price + this.trip.bloggerTrips[0].bloggerAward,
                        currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                        dates: `${ moment(this.trip.bloggerTrips[0].start).format('DD MMM') } - ${ moment(this.trip.bloggerTrips[0].finish).format('DD MMM') }`
                    };
                }
                if (this.trip.arrivals[0] && this.trip.arrivals[0].start && this.trip.arrivals[0].finish) {
                    return {
                        price: +this.trip.arrivals[0].price,
                        currency: this.trip.arrivals[0].currency,
                        dates: `${ moment(this.trip.arrivals[0].start).format('DD MMM') } - ${ moment(this.trip.arrivals[0].finish).format('DD MMM') }`
                    };
                } 
                return null;
            },
            formattedMainType() {
                if(this.trip.mainType) {
                    return this.types.filter(item => (item.code === this.trip.mainType))[0].title;
                } else {
                    return ''
                }
            },
            formattedAdditionalTypes() {
                if(this.trip.additionalTypes) {
                    return this.types.filter(item => (this.trip.additionalTypes.indexOf(item.code) >= 0)).map(item => item.title);
                } else {
                    return ''
                }
            },
            formattedLabels() {
                return this.labels.filter(item => (item.code === this.trip.label))[0].title;
            },
            status () {
                return TRIP_STATUS_TITLE[this.trip.status];
            },
            price () {
                let price = this.trip.arrivals[0] ? this.trip.arrivals[0].price : 0;
                return new Intl.NumberFormat('ru-RU').format(price);
            },
            mainPlacesArray () {
                return this.trip && this.trip.mainPlaces && this.trip.mainPlaces[0] ? this.trip.mainPlaces[0].split(',') : []
            },
            inFavorites() {
                if (this.user._id) {
                    return this.user.favoriteTrips.indexOf(this.trip._id) >= 0;
                }
                return false;
            },
            testLink() {
                return `${ process.env.VUE_APP_CURRENT_URL }/site/${ this.trip.code }`;
            },
            subdomain() {
                return `${ process.env.VUE_APP_PROTOCOL }${ this.trip.code }${ process.env.VUE_APP_SUBDOMAIN_POSTFIX }`;
            }
        },
        methods: {
            async open(code) {
                if(this.bloggersList) {
                    await this.$router.push({ name: 'trip-bloggers', params: { code } });    
                } else {
                    await this.$router.push({ name: 'trip', params: { code } });
                }
            },
            async edit(id) {
                if(this.user._id) {
                    await this.$router.push({ name: 'trip-edit', params: { id } });
                } else {
                    this.authorization();
                    this.$root.$once('authorized', async () => {
                        await this.$router.push({ name: 'trip-edit', params: { id } });
                    });
                }
            },
            async copy(id) {
                if(this.user._id) {
                    await this.$emit('copy', { id });
                } else {
                    this.authorization();
                    this.$root.$once('authorized', async () => {
                        await this.$emit('copy', { id });
                    });
                }
            },
            duration(arr) {
                let duration = arr.reduce((duration, currentValue) => {
                    return duration + currentValue.duration
                }, 0)
                return duration
            },
            async favoriteAdd(id) {
                await this.$emit('favoriteAdd', { id });
                await this.sendFeedback(FEEDBACK_TYPE.FAVORITE_ADD);
            },
            async favoriteDelete(id) {
                await this.$emit('favoriteDelete', { id });
            },
            async setStatus(id, status) {
                if(this.user._id) {
                    await this.$emit('setStatus', { id, status });
                } else {
                    this.authorization();
                    this.$root.$once('authorized', async () => {
                        await this.$emit('setStatus', { id, status });
                    });
                }
            },
            async downloadProgram() {
                if(this.user._id) {
                    await this.sendFeedback(FEEDBACK_TYPE.DOWNLOAD_PROGRAM);
                    this.popupDownload = true;
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        await this.sendFeedback(FEEDBACK_TYPE.DOWNLOAD_PROGRAM);
                        this.popupDownload = true;
                    });
                }
            },
            async sendFeedback(type) {
                this.feedback = {
                    trip: this.trip._id,
                    name: this.user.firstname,
                    phone: this.user.phone,
                    email: this.user.email,
                    type: type,
                    link: document.location.href
                };
                await store.commit('feedbacks/SET_ENTITY', this.feedback);
                await store.dispatch('feedbacks/save');
                await store.commit('feedbacks/CLEAR_ENTITY');
            },
            authorization() {
                if(!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                }
            },
        }
    }
</script>

<style lang="scss">
.desktop-trip-preview-card {
	display: flex;
	flex-direction: row;
	width: 100%;
    min-height: 320px;
	background: #FFFFFF;
	border-radius: 20px;
	margin-bottom: 20px;
	overflow: hidden;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.02), 0px 4px 16px rgba(44, 47, 56, 0.12);
    // @media all and (min-width: 1280px) {
    //     margin: auto;
    //     max-width: 1400px;
    // }
    &__carousel {
        position: relative;
        width: 360px;
        min-height: 100%;
        &__button {
            position: absolute;
            left: 16px;
            bottom: 16px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6.5px 8px;
            background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
            border-radius: 158px;
            cursor: pointer;
            &__icon {
                width: 16px;
                height: 16px;
            }

            &__text {
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #FFFFFF;
                flex: none;
                order: 1;
                flex-grow: 0;
                margin: 0px 4px;
            }
        }

        &__labels {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            position: absolute;
            top: 16px;
            left: 16px;
            display: flex;

            &__item {
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.015em;
                color: #FFFFFF;
                background: #48A7FF;
                margin-right: 5px;

                &.yellow {
                    background: #FFCD1D;
                    color: #44414D;
                }
                &.soldOut {
                    background: red;
                }
                &.red {
                    background: red;
                    color: #44414D;
                }
            }
        }
    }

    &__place {
        position: absolute;
        display: flex;
        top: 20px;
        left: 24px
    }

    &__img {
        height: 100%;
        width: 360px;
        background-size: cover;
        cursor: pointer;
    }

    &__info-container {
        flex-direction: column;
        width: 100%;
    }

    &__info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 32px;
        border-bottom: 1px solid #F6F7F9;
    }

    &__title{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        margin-bottom: 4px;
    }

    &__stars {
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */
        // text-align: right;
        letter-spacing: -0.02em;
        color: #707378;
    }

    &__comment{
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #484848;
        opacity: 0.5;
        margin-bottom: 16px;
    }

    &__details-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 5px;
        padding-top: 5px;

        @media all and (min-width: 768px) {
            flex-direction: column;
        }

        &__details {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #484848;
            white-space: nowrap;
        }

        &__price {
            font-weight: 600;
        }

        &__dates {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 8px;
            @media all and (min-width: 768px) {
                justify-content: flex-end;
            }

            &__icon {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }

            &__text {
                font-size: 14px;
                line-height: 140%;
                text-align: right;
                letter-spacing: -0.02em;
                color: #707378;
            }
        }
    }
    &__description-and-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__description {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #44414D;
        padding: 20px 0 15px 20px;
        &__line {
            margin-right: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 14px;
            &.party-line {
                margin-top: 5px;
                // margin-bottom: 0;
            }
            }
        &__name {
            width: 120px;
            margin-right: 20px;
            @media all and (min-width: 1280px) {
                width: 140px;
                margin-right: 100px;
            }
        }
        &__value span {
            white-space: nowrap;
            // display: flex;
            // justify-content: flex-start;
        }
        &__party {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 28px;
            width: 125px;
            &__participant {
                margin-right: -8px;
            }
        }

        &__span {
            color: #DA0A63;
            margin-left: 4px;
        }

        &__pdf {
            display: flex;
            color: #DA0A63;
            align-items: center;
            // margin-top: 15px;
            cursor: pointer;
        }
    }

    &__button-grey {
        padding: 10px 20px 10px 10px;
        margin-bottom: 18px;
        width: calc(100% - 48px);
        height: 32px;
        background: #F6F7F9;;;
        border-radius: 10px;
        align-self: center;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        letter-spacing: -0.02em;
        color: #484848;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        margin: 35px 20px 0px;
    }

    &__button {
        width: 195px;
        height: 48px;
        border: 1px solid #DA0A63 !important;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        line-height: 140%;
        padding: 10px;
        margin-bottom: 16px;
        letter-spacing: -0.02em;
        background: #FFFFFF;
        color: #DA0A63;
        cursor: pointer;
        &__icon {
            width: 15px;
            height: 15px;
            margin-left: 5px;
        }
    }

    &__button-fill {
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        color: #FFFFFF;
    }
}
</style>