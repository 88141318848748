<template>
    <div class="view-trip-list" v-if="translates && translates[langUrl]">
        <nav v-if="isDesktop && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="userFavorits">{{ translates.navigation.button_route_favorits[lang] }}</div>
        </nav>
        <h1 class="view-trip-list__title">{{ translates[langUrl].pageTitle[lang] }}</h1>
        <div v-if="trips && trips.length === 0" class="view-trip-list__content__empty">
            {{ translates[langUrl].noFavorites[lang] }}
        </div>
        <div class="view-trip-list__content" v-else>
            <div v-if="isDesktop">
                <div class="view-trip-list__preview">
                    <template v-for="(trip, index) in trips">
                        <DesktopPreviewCard
                            :key="index"
                            :trip="trip"
                            :buttons="['favorites']"
                            @favoriteAdd="favoriteAdd"
                            @favoriteDelete="favoriteDelete" />
                    </template>
                </div>
            </div>
            <div v-else>
                <div class="view-trip-list__preview">
                    <template v-for="(trip, index) in trips">
                        <PreviewCard
                            :key="index"
                            :trip="trip"
                            :buttons="['favorites']"
                            @favoriteAdd="favoriteAdd"
                            @favoriteDelete="favoriteDelete" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import PreviewCard from '../../components/common/trip/PreviewCard.vue';
    import DesktopPreviewCard from '../../components/desktop/trip/PreviewCard.vue'
    import { TRIP_STATUS } from '@/vars';

    export default {
        name: 'Favorites',
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            PreviewCard,
            DesktopPreviewCard,
        },
        data: () => ({
            isDesktop: false,
            langUrl: '/user/favorites'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await this.getTrips();
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async getTrips() {
                await store.dispatch('trips/favoriteFetch');
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async favoriteAdd({ id }) {
                if (!this.user._id) {
                    await this.$router.push({ name: 'user-authorization' });
                }
                await store.dispatch('trips/favoriteAdd', { id });
                await store.dispatch('auth/fetch');
                await this.getTrips();
            },
            async favoriteDelete({ id }) {
                if (!this.user._id) {
                    await this.$router.push({ name: 'user-authorization' });
                }
                await store.dispatch('trips/favoriteDelete', { id });
                await store.dispatch('auth/fetch');
                await this.getTrips();
            },
            async userFavorits () {
                await this.$router.push({ name: 'user-favorites' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
        }
    }
</script>

<style lang="scss">
.view-trip-list {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    @media all and (min-width: 768px) {
        padding: 0 32px;
    }
    @media all and (min-width:1464px) {
        padding: 0;
    }
    &__menu{
        margin: 13px 24px 0;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #44414D;
        @media all and (min-width:768px) {
            margin: 13px 0 0;
        }
    }
    &__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #3A4256;
        margin: 32px 24px 12px;
        @media all and (min-width:768px) {
        font-weight: bold;
        font-size: 38px;
        line-height: 46px;
        color: #273155;
        margin: 40px 0 36px;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        &__empty {
            font-weight: bold;
            font-size: 18px;
            line-height: 140%;
            color: #3A4256;
            text-align: center;
            margin: 10vw auto;
        }
    }
    &__preview {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media all and (min-width:768px) {
            flex-direction: column;
            justify-content: space-between;
            // padding: 0 32px;
        }
    }
}

</style>
